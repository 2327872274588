// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
import compose from 'recompose/compose'

import map from 'lodash/map'
import orderBy from 'lodash/orderBy'
import filter from 'lodash/filter'
import kebabCase from 'lodash/kebabCase'
import startsWith from 'lodash/startsWith'
import find from 'lodash/find'
import isUndefined from 'lodash/isUndefined'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import { injectIntl } from 'gatsby-plugin-intl'

import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import './style.less'

import StandardPageWrapper from '../standard-page-wrapper'
import '../standard-page-wrapper/style.less'

import BlockParser from '../block-parser'
import '../block-parser/style.less'

import ArticleSchema from '../schema/article-schema'

import indexImage from '../../images/banners/launch.jpg'

import about from '../../seo/about.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
const { Fragment } = React

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const pageQuery = graphql`
  query PageQuery($position: Float, $positionP1: Float) {
    allResources(filter: { position: { gte: $position, lt: $positionP1 } }) {
      nodes {
        title {
          content
          lang
        }
        position
        routeSlug
        contentBlocks {
          type
          contentBlocks {
            type
            subType
            content
            resource {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  placeholder: TRACED_SVG
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** PageTemplate */
const PageTemplate = (props) => {
  const {
    data: {
      allResources: { nodes },
    },
    pageContext: { position },
    intl: { locale },
  } = props

  const ordered = orderBy(nodes, ['position', 'asc'])
  const node = filter(nodes, ['position', position])[0]

  const chapterTitle = filter(node.title, ['lang', locale])[0].content
  const chapterSlug = `${locale}${node.routeSlug}`

  const contentNode = find(ordered, (n) => n.contentBlocks.length > 0)
  const contentNodeContentBlocks = contentNode.contentBlocks
  const contentNodeAbstractBlockForChapter = find(
    contentNodeContentBlocks,
    (block) =>
      block.type === 'sub-text' ||
      block.type === 'text' ||
      block.type === 'block'
  )
  let chapterAbstract = about.text
  if (isUndefined(contentNodeAbstractBlockForChapter) === false) {
    if (contentNodeAbstractBlockForChapter.type === 'sub-text') {
      chapterAbstract = contentNodeAbstractBlockForChapter.content
    }
    if (contentNodeAbstractBlockForChapter.type === 'text') {
      chapterAbstract = contentNodeAbstractBlockForChapter.content
    }
    if (contentNodeAbstractBlockForChapter.type === 'block') {
      chapterAbstract =
        contentNodeAbstractBlockForChapter.contentBlocks[0].content
    }
  }

  const contentNodeImageBlockForChapter = find(
    contentNodeContentBlocks,
    (block) => block.type === 'carousel' || block.type === 'image'
  )

  let chapterImage = indexImage
  if (isUndefined(contentNodeImageBlockForChapter) === false) {
    if (contentNodeImageBlockForChapter.type === 'carousel') {
      if (contentNodeImageBlockForChapter.resources.length > 0) {
        chapterImage =
          contentNodeImageBlockForChapter.resources[0].resource.childImageSharp
            .gatsbyImageData.images.fallback.src
      }
    } else if (contentNodeImageBlockForChapter.type === 'image') {
      chapterImage =
        contentNodeImageBlockForChapter.resource.childImageSharp.gatsbyImageData
          .images.fallback.src
    }
  }

  const pageSchema = {
    title: chapterTitle,
    slug: chapterSlug,
    abstract: chapterAbstract,
    breadcrumbs: [
      { title: 'Cover Page', slug: '' },
      { title: chapterTitle, slug: chapterSlug },
    ],
    cover: chapterImage,
  }

  return (
    <StandardPageWrapper
      className="book-page"
      pageSchema={pageSchema}
      {...props}
    >
      {map(ordered, ({ title, routeSlug, contentBlocks }, index) => {
        const thisLocaleTitle = filter(title, ['lang', locale])[0].content
        const enLocaleTitle = filter(title, ['lang', 'en'])[0].content
        let sectionTitle = chapterTitle
        let sectionSlug = chapterSlug
        let sectionAbstract = chapterAbstract
        let sectionImage = chapterImage

        if (index !== 0) {
          sectionTitle = `${chapterTitle}; Section ${index}. ${thisLocaleTitle}`
          sectionSlug = `${chapterSlug}#${routeSlug}`

          const contentNodeAbstractBlockForSection = find(
            contentBlocks,
            (block) =>
              block.type === 'sub-text' ||
              block.type === 'text' ||
              block.type === 'block'
          )
          if (contentNodeAbstractBlockForSection.type === 'sub-text') {
            sectionAbstract = contentNodeAbstractBlockForSection.content
          }
          if (contentNodeAbstractBlockForSection.type === 'text') {
            sectionAbstract = contentNodeAbstractBlockForSection.content
          }
          if (contentNodeAbstractBlockForSection.type === 'block') {
            sectionAbstract =
              contentNodeAbstractBlockForSection.contentBlocks[0].content
          }

          const contentNodeImageBlockForSection = find(
            contentBlocks,
            (block) => block.type === 'carousel' || block.type === 'image'
          )

          if (isUndefined(contentNodeImageBlockForSection) === false) {
            if (contentNodeImageBlockForSection.type === 'carousel') {
              if (contentNodeImageBlockForSection.resources.length > 0) {
                sectionImage =
                  contentNodeImageBlockForSection.resources[0].resource
                    .childImageSharp.gatsbyImageData.images.fallback.src
              }
            } else if (contentNodeImageBlockForSection.type === 'image') {
              sectionImage =
                contentNodeImageBlockForSection.resource.childImageSharp
                  .gatsbyImageData.images.fallback.src
            }
          }
        }

        const articleSchemaData = {
          name: sectionTitle,
          slug: sectionSlug,
          articleBody: sectionAbstract,
          breadcrumbs: [
            { title: 'Cover Page', slug: '' },
            { title: chapterTitle, slug: chapterSlug },
            { title: sectionTitle, slug: sectionSlug },
          ],
          cover: sectionImage,
        }

        return (
          <Fragment>
            <ArticleSchema data={articleSchemaData} />
            {/*
              {index === 0 && (
                <h1 id={`${kebabCase(enLocaleTitle)}`}>{thisLocaleTitle}</h1>
              )}
              {index !== 0 && (
                <h2 id={`${kebabCase(enLocaleTitle)}`}>{thisLocaleTitle}</h2>
              )}
              */}
            {map(contentBlocks, (block) => (
              <BlockParser block={block} locale={locale} />
            ))}
          </Fragment>
        )
      })}
    </StandardPageWrapper>
  )
}

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPageTemplate = compose(
  injectIntl // Add state
)(PageTemplate)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Exports
// ----------------------------------------------------------------------------
export default ComposedPageTemplate
